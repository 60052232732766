/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable consistent-return */
import React, { useState, useEffect, Suspense, lazy } from 'react'
import { Accordions } from '@ashleynexvelsolutions/accordions'
import { ContactFormV2 } from '@ashleynexvelsolutions/contact-form-v2'
import { Hero } from '@ashleynexvelsolutions/hero'
import { IconTextBoxes } from '@ashleynexvelsolutions/icon-text-boxes'
import { InteriorContent } from '@ashleynexvelsolutions/interior-content'
import { Portal } from '@ashleynexvelsolutions/portal'
import { SdJobForm } from '@ashleynexvelsolutions/sd-job-form'
import { Seo2 } from '@ashleynexvelsolutions/seo2'
import { ServicesMenu } from '@ashleynexvelsolutions/services-menu'
import { graphql, Script } from 'gatsby'
// eslint-disable-next-line import/extensions
import resizerScript from '../../node_modules/iframe-resizer/js/iframeResizer.js'
import { Layout } from '../components/Layout'
import {
  SeoFunction,
  PageSpecificSchemaFunction,
  InteriorHeroFunction,
  InteriorServicesMenuFunction,
  AskQuestionSidebarFunction,
  NewsletterSidebarFunction,
  InteriorContentFunction,
  SdDifferenceFunction,
  ServiceAreasFunction,
  SdContactPageFormFunction,
  CareerAccordionsFunction,
  SdJobFormFunction,
} from '../DataImports'

const SDdifference = lazy(() =>
  import('@ashleynexvelsolutions/sd-difference').then(module => ({
    default: module.SDdifference,
  })),
)
const ServiceAreas = lazy(() =>
  import('@ashleynexvelsolutions/service-areas').then(module => ({
    default: module.ServiceAreas,
  })),
)
const SdContactPageForm = lazy(() =>
  import('@ashleynexvelsolutions/sd-contact-page-form').then(module => ({
    default: module.SdContactPageForm,
  })),
)
const PageSpecificSchema = lazy(() =>
  import('@ashleynexvelsolutions/page-specific-schema').then(module => ({
    default: module.PageSpecificSchema,
  })),
)

const Page = ({ data }) => {
  useEffect(() => {
    if (data.wpPage.title === 'Newsletter' || data.wpPage.title === 'Financial Calculator') {
      const script = document.createElement('script')

      script.src = resizerScript
      script.async = true

      document.body.appendChild(script)

      return () => {
        document.body.removeChild(script)
      }
    }
  }, [data.wpPage.title])
  useEffect(() => {
    if (data.wpPage.title === 'Newsletter' || data.wpPage.title === 'Financial Calculator') {
      const script = document.createElement('script')

      // eslint-disable-next-line no-undef
      script.append = iFrameResize({ log: true }, 'iframe')
      script.async = true

      document.body.appendChild(script)

      return () => {
        document.body.removeChild(script)
      }
    }
  }, [data.wpPage.title])

  const [formModal, setFormModal] = useState(false)
  return (
    <Layout>
      <Hero {...InteriorHeroFunction(data)} />
      <div className="bg-white">
        <div className="subPage container mx-auto px-6 py-10 lg:grid lg:py-28 lg:grid-cols-[1fr_3fr] lg:gap-x-20">
          <div className="lg:col-start-2 lg:col-end-2">
            <InteriorContent
              handleMainBtn={() => setFormModal(true)}
              {...InteriorContentFunction(data)}
            />
            {data.wpPage.title === 'Reviews' && (
              <>
                <Script id="reviewScript" src={process.env.GATSBY_BIRDEYE_REVIEW_URL} />
                <div id={process.env.GATSBY_BIRDEYE_REVIEW_ID} />
              </>
            )}
            {data.wpPage.title === 'Contact' && (
              <Suspense fallback="loading">
                <SdContactPageForm {...SdContactPageFormFunction()} />
              </Suspense>
            )}
            {data.wpPage.title === 'Careers' && (
              <>
                <Accordions {...CareerAccordionsFunction()} />
                <div className="mb-11">
                  <SdJobForm {...SdJobFormFunction()} />
                </div>
              </>
            )}
          </div>
          <div className="flex flex-col gap-y-10 lg:col-start-1 lg:col-end-1 lg:row-start-1">
            <ServicesMenu {...InteriorServicesMenuFunction()} />
            <IconTextBoxes
              handleMainBtn={() => setFormModal(true)}
              {...AskQuestionSidebarFunction()}
            />
            <IconTextBoxes {...NewsletterSidebarFunction()} />
          </div>
        </div>
      </div>
      <Suspense fallback="loading">
        <SDdifference {...SdDifferenceFunction()} />
      </Suspense>
      <Suspense fallback="loading">
        <ServiceAreas {...ServiceAreasFunction()} />
      </Suspense>
      <Portal isOpen={formModal} onClose={() => setFormModal(false)}>
        <ContactFormV2
          type="modal"
          title="Let's Chat!"
          subTitle="Get a Free Consultation"
          borderColor="border-green-light"
          bgColor="bg-white"
          subTitleColor="text-green-lighter"
          subtitleBorderColor="bg-green-lighter"
          inputColor="text-[#363636]"
          inputOrderNumberColor="before:text-grey-lightest"
          inputBorderColor="border-grey-dark"
          selectBorderColor="border-gray-300"
          selectTextColor="text-gray-300"
          buttonBgColor="bg-green-light"
        />
      </Portal>
      <Suspense fallback="loading">
        <PageSpecificSchema {...PageSpecificSchemaFunction(data)} />
      </Suspense>
    </Layout>
  )
}
export const query = graphql`
  query ($id: String!) {
    wpPage(id: { eq: $id }) {
      seo {
        title
        metaDesc
        opengraphTitle
        opengraphDescription
        opengraphUrl
        opengraphSiteName
        opengraphType
        opengraphImage {
          localFile {
            publicURL
          }
        }
        canonical
        twitterTitle
        twitterDescription
        twitterImage {
          localFile {
            publicURL
          }
        }
      }
      nexvelschemapagesposts {
        videos {
          title
          url
          description
          thumbnailImage {
            localFile {
              publicURL
            }
          }
          uploadDate
        }
        questionsAndAnswers {
          question
          answer
        }
        maps {
          mapUrl
        }
        digitaldocuments {
          title
        }
        images {
          image {
            localFile {
              publicURL
            }
            date(formatString: "LL")
            description
            title
          }
        }
      }
      title
      uri
      featuredImage {
        node {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, quality: 100)
            }
          }
        }
      }
      interiorHero {
        interiorHeroFeaturedImageAltText
        interiorHeroTitle
        interiorHeroSubtitle
      }
      interiorSection1 {
        interiorSection1ContentImage {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        interiorSection1AltText
        interiorSection1Content
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`
export default Page

export const Head = ({ data }) => <Seo2 {...SeoFunction(data)} />
